import LinkWrapper from "../../../../global/components/LinkWrapper/LinkWrapper";
import { StyledDropdownButton } from "../ThemStyledDropdown.styled";
import { DropdownButtonProps } from "../ThemeStyledDropdown.types";

const DropdownButton: React.FC<DropdownButtonProps> = ({
  dropdown,
  selectedItem,
  openDropdown,
  closeDropdown,
  isOpen,
  openOnHover,
}) => {
  return (
    <StyledDropdownButton
      settings={dropdown}
      className="dropdown__button"
      disabled={selectedItem?.disabled ?? false}
      isOpen={isOpen}
      onMouseEnter={openOnHover ? openDropdown : undefined}
      onMouseLeave={openOnHover ? closeDropdown : undefined}
      onClick={!isOpen ? openDropdown : undefined}
      data-testid="dropdown-button"
    >
      <i />
      <LinkWrapper
        className="dropdown__button__label"
        linkStyle={dropdown.selectedItemLink}
        fontStyle={
          selectedItem?.disabled
            ? dropdown.selectedItemDisabledTypography
            : dropdown.selectedItemTypography
        }
        href=""
        basicColor={
          selectedItem?.disabled
            ? dropdown.selectedItemDisabledColor
            : undefined
        }
      >
        {typeof selectedItem?.label === "function"
          ? selectedItem.label(dropdown, true, true)
          : selectedItem?.label}
      </LinkWrapper>
    </StyledDropdownButton>
  );
};

export default DropdownButton;
