import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { nanoid } from "nanoid";
import { useTheme } from "styled-components";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { convertDropdownIdToValues } from "../../../settingsPatterns/settingsPatterns.methods";
import { useLayoutControllerContext } from "../../../structure/Contexts/LayoutControllerContext";
import { ThemeStyledDropdownProps } from "./ThemeStyledDropdown.types";

export const useDropdownAction = (
  { onClickItem, items, selectedItemId, style }: ThemeStyledDropdownProps,
  rect: DOMRect
) => {
  const dropdowns = (useTheme() as IThemeState).globalObjects.dropdowns;
  const [isOpen, setIsOpen] = useState(false);
  const { lastClickedId, setLastClickedId } = useLayoutControllerContext();
  const dropdownId = useRef(nanoid());

  const dropdownStyle = useMemo(
    () => convertDropdownIdToValues(style, dropdowns),
    [style, dropdowns]
  );

  const closeDropdown = useCallback(() => {
    setIsOpen(false);
  }, []);

  const openDropdown = useCallback(() => {
    setLastClickedId(dropdownId.current);
    setIsOpen(true);
  }, []);

  useEffect(() => {
    if (lastClickedId !== dropdownId.current && isOpen) {
      closeDropdown();
    }
  }, [lastClickedId]);

  return {
    isOpen,
    closeDropdown,
    openDropdown,
    onClickItem,
    items,
    selectedItemId,
    dropdownStyle,
    openOnTop:
      (rect.bottom ?? 0) + dropdownStyle.dropdownHeight >=
      global.window?.innerHeight,
    openOnHover: dropdownStyle.openOnHover,
  };
};
