import React, { useEffect } from "react";
import classNames from "classnames";
import { createPortal } from "react-dom";
import {
  StyledDropdown,
  StyledPortalDropdownWrapper,
} from "./ThemStyledDropdown.styled";
import { useDropdownAction } from "./ThemeStyledDropdown.method";
import {
  PortalDropdownWrapperProps,
  ThemeStyledDropdownProps,
} from "./ThemeStyledDropdown.types";
import DropdownButton from "./elements/DropdownButton";
import ExtendedDropdown from "./elements/ExtendedDropdown";

const defaultRect = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
} as DOMRect;

const Wrapper: React.FC<PortalDropdownWrapperProps> = ({
  children,
  ...rest
}) => {
  return (
    <StyledPortalDropdownWrapper {...rest}>
      {children}
    </StyledPortalDropdownWrapper>
  );
};

const Portal: React.FC<{ isOpen: boolean } & PortalDropdownWrapperProps> = ({
  children,
  isOpen,
  ...rest
}) => {
  if (!isOpen) return null;
  const target = document.body;
  return createPortal(Wrapper({ children, ...rest }), target);
};

const ThemeStyledDropdown: React.FC<ThemeStyledDropdownProps> = (props) => {
  const refWrapper = React.useRef<HTMLDivElement>(null);
  const [rect, setRect] = React.useState<DOMRect>(defaultRect);

  const {
    openDropdown,
    onClickItem,
    closeDropdown,
    items,
    selectedItemId,
    dropdownStyle,
    isOpen,
    openOnTop,
    openOnHover,
  } = useDropdownAction(props, rect);

  useEffect(() => {
    if (refWrapper) {
      document.body.addEventListener("wheel", () => {
        setRect(
          refWrapper.current?.children[0].getBoundingClientRect() ?? defaultRect
        );
      });
      document.body.addEventListener("scroll", () => {
        setRect(
          refWrapper.current?.children[0].getBoundingClientRect() ?? defaultRect
        );
      });
      window.addEventListener("resize", () => {
        setRect(
          refWrapper.current?.children[0].getBoundingClientRect() ?? defaultRect
        );
      });
    }

    return () => {
      if (refWrapper) {
        document.body.removeEventListener("wheel", () => {
          setRect(
            refWrapper.current?.children[0].getBoundingClientRect() ??
              defaultRect
          );
        });
        document.body.removeEventListener("scroll", () => {
          setRect(
            refWrapper.current?.children[0].getBoundingClientRect() ??
              defaultRect
          );
        });
        window.removeEventListener("resize", () => {
          setRect(
            refWrapper.current?.children[0].getBoundingClientRect() ??
              defaultRect
          );
        });
      }
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      setRect(
        refWrapper.current?.children[0].getBoundingClientRect() ?? defaultRect
      );
    }
  }, [isOpen]);

  if (!global.window) {
    return (
      <StyledDropdown
        alignment={dropdownStyle.dropdownAlign}
        className="dropdown"
      >
        <DropdownButton
          dropdown={dropdownStyle}
          selectedItem={
            items.find((item) => item.id === selectedItemId) ?? {
              id: "",
              label: "",
            }
          }
          openDropdown={openDropdown}
          closeDropdown={closeDropdown}
          isOpen={isOpen}
          openOnHover={openOnHover}
        />
      </StyledDropdown>
    );
  }

  return (
    <StyledDropdown
      alignment={dropdownStyle.dropdownAlign}
      className={classNames("dropdown", {
        [props.className ?? ""]: props.className,
      })}
      ref={refWrapper}
    >
      <DropdownButton
        dropdown={dropdownStyle}
        selectedItem={
          items.find((item) => item.id === selectedItemId) ?? {
            id: "",
            label: "",
          }
        }
        openDropdown={openDropdown}
        closeDropdown={closeDropdown}
        isOpen={isOpen}
        openOnHover={openOnHover}
      />

      <Portal
        isOpen={isOpen}
        rect={rect}
        openOnTop={openOnTop}
        align={dropdownStyle.columnAlignment}
        diffrentWidthBetweenButtonAndDropdown={
          rect.width - dropdownStyle.columnWidth
        }
      >
        <ExtendedDropdown
          dropdown={dropdownStyle}
          items={items}
          onClickItem={onClickItem}
          selectedItemId={selectedItemId}
          closeDropdown={closeDropdown}
          openDropdown={openDropdown}
          openOnHover={openOnHover}
          searchComponent={
            props.searchComponent && props.searchComponent(dropdownStyle)
          }
        />
      </Portal>
    </StyledDropdown>
  );
};

export default ThemeStyledDropdown;
