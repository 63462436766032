import styled from "styled-components";
import { IIcon } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import {
  AlignmentHorizontalOption,
  IGlobalDropdownObject,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { noEmptyValue } from "../../../helpers/helpers";
import composeCss, {
  alignmentOptionsToValue,
} from "../../../settingsPatterns/composeCss";
import { convertColorIdToHex } from "../../../settingsPatterns/settingsPatterns.methods";
import { PortalDropdownWrapperProps } from "./ThemeStyledDropdown.types";

const icon = (icon: IIcon, spacing: number) => {
  return (
    noEmptyValue(icon.iconUrl) &&
    `
& {
display: flex;
align-items: center;
justify-content: space-between;
}

& > i,
& > i {
order: ${icon.position === "LEFT" ? 0 : 1};
}

& > i:before,
& > i:before {
display: block;
content: "";
background: url(${icon.iconUrl});
background-repeat: no-repeat;
background-size: 100%;
height: ${icon.size}px;
width: ${icon.size}px;
margin-left: ${spacing}px;
margin-right: ${spacing}px;
}
`
  );
};

export const StyledDropdownButton = styled.div<{
  settings: IGlobalDropdownObject;
  disabled: boolean;
  isOpen: boolean;
}>(({ settings, theme, disabled, isOpen }) => {
  return `
  width: ${settings.dropdownWidth}px;
  max-width: 100%;
  height: ${settings.dropdownHeight}px;

  cursor: pointer; 
  position: relative; //For not showing shadow on div

  display: flex;
  justify-content: space-between;
  text-align: ${settings.selectedItemAlignment};

  background: ${
    disabled
      ? convertColorIdToHex(
          settings.dropdownBackground.disabledColor,
          theme.colorPalette
        )
      : convertColorIdToHex(
          settings.dropdownBackground.color,
          theme.colorPalette
        )
  };

  border-color: ${convertColorIdToHex(
    settings.dropdownBorder.color,
    theme.colorPalette
  )};
  border-radius:${settings.dropdownBorder.radius}px;
  ${composeCss.borderWidth(settings.dropdownBorder.width)};

  &:hover {
    background: ${convertColorIdToHex(
      settings.dropdownBackground.hoverColor,
      theme.colorPalette
    )};
  }

  ${
    isOpen === true
      ? icon(settings.openIcon, settings.openIcon.spacing)
      : icon(settings.closeIcon, settings.closeIcon.spacing)
  };

  .dropdown__button {
    &__label {
      align-self: center;
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;      
    }
  }

  z-index: var(--zindex-dropdown-button);
  `;
});

export const StyledDropdown = styled.div<{
  alignment: AlignmentHorizontalOption;
}>(({ alignment }) => {
  return `
    display: flex;
    width: 100%;
    justify-content: ${alignmentOptionsToValue(alignment)};
  `;
});

export const StyledExtendedDropdown = styled.div<{
  settings: IGlobalDropdownObject;
}>(({ settings, theme }) => {
  return `
    width: ${settings.columnWidth}px;
    max-height: ${settings.columnHeight}px;

    overflow-y: overlay;

    border-color: ${convertColorIdToHex(
      settings.columnBorder.color,
      theme.colorPalette
    )};
    border-radius:${settings.columnBorder.radius}px;
    ${composeCss.borderWidth(settings.columnBorder.width)};
    
    ${
      settings.columnShadow
        ? `box-shadow: ${settings.columnShadowPositionX}px ${
            settings.columnShadowPositionY
          }px ${settings.columnShadowBlur}px ${convertColorIdToHex(
            settings.columnShadowColor,
            theme.colorPalette
          )}`
        : ""
    };
    z-index: var(--zindex-extended-dropdown);


    .extended-dropdown__item__label {
      width: 100%;
    }
    `;
});

export const StyledDropdownItem = styled.div<{
  settings: IGlobalDropdownObject;
  selectedItem: boolean;
  disabled: boolean;
}>(({ settings, theme, selectedItem, disabled }) => {
  return `
      width: 100%;
      display: flex;
      word-break: break-word;

      background: ${
        selectedItem
          ? convertColorIdToHex(
              settings.dropdownItemBackground.activeColor,
              theme.colorPalette
            )
          : disabled
          ? convertColorIdToHex(
              settings.dropdownItemBackground.disabledColor,
              theme.colorPalette
            )
          : convertColorIdToHex(
              settings.dropdownItemBackground.color,
              theme.colorPalette
            )
      };

      padding: ${composeCss.padding(settings.dropdownItemPadding)};

      &:hover {
        background: ${convertColorIdToHex(
          disabled
            ? settings.dropdownItemBackground.disabledColor
            : settings.dropdownItemBackground.hoverColor,
          theme.colorPalette
        )};
      }

      .extended-dropdown__item__label {
        display: flex;
        justify-content: ${alignmentOptionsToValue(
          settings.dropdownItemAlignment
        )};
      }

    `;
});

export const StyledPortalDropdownWrapper =
  styled.div<PortalDropdownWrapperProps>(
    ({ align, rect, diffrentWidthBetweenButtonAndDropdown, openOnTop }) => {
      return `
        position: fixed;
        z-index: var(--zindex-extended-dropdown);


        ${
          openOnTop
            ? `bottom: ${window.innerHeight - rect.y}px`
            : `top: ${rect.y + rect.height}px`
        };
        
        left: ${
          rect.x +
          (align === AlignmentHorizontalOption.LEFT
            ? 0
            : align === AlignmentHorizontalOption.RIGHT
            ? diffrentWidthBetweenButtonAndDropdown
            : diffrentWidthBetweenButtonAndDropdown / 2)
        }px;
  `;
    }
  );
