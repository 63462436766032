import { useRef } from "react";
import LinkWrapper from "../../../../global/components/LinkWrapper/LinkWrapper";
import useOnClickOutside from "../../../../shared/hooks/useOnClickOutside";
import {
  StyledDropdownItem,
  StyledExtendedDropdown,
} from "../ThemStyledDropdown.styled";
import { ExtendedDropdownProps } from "../ThemeStyledDropdown.types";

const ExtendedDropdown: React.FC<ExtendedDropdownProps> = ({
  dropdown,
  items,
  onClickItem,
  selectedItemId,
  closeDropdown,
  openDropdown,
  searchComponent,
  openOnHover,
}) => {
  const dropdownWrapper = useRef<HTMLDivElement>(null);
  useOnClickOutside(dropdownWrapper, () => {
    closeDropdown();
  });

  return (
    <StyledExtendedDropdown
      settings={dropdown}
      ref={dropdownWrapper}
      className="extended-dropdown"
      onMouseLeave={openOnHover ? closeDropdown : undefined}
      onMouseEnter={openDropdown}
    >
      {searchComponent}
      {items.map((item) => {
        return (
          <StyledDropdownItem
            settings={dropdown}
            className="extended-dropdown__item"
            selectedItem={item.id === selectedItemId}
            disabled={item.disabled ?? false}
            key={item.id}
          >
            <LinkWrapper
              linkStyle={dropdown.dropdownItemLink}
              fontStyle={dropdown.dropdownItemTypography}
              href=""
              onClick={() => {
                onClickItem(item.id, closeDropdown);
                closeDropdown();
              }}
              className="extended-dropdown__item__label"
              basicColor={
                item?.disabled
                  ? dropdown.dropdownItemDisabledTextColor
                  : undefined
              }
            >
              {typeof item.label === "function"
                ? item.label(dropdown, item.id === selectedItemId, false)
                : item?.label}
            </LinkWrapper>
          </StyledDropdownItem>
        );
      })}
    </StyledExtendedDropdown>
  );
};

export default ExtendedDropdown;
